import { Component, OnInit, ViewChild, ElementRef, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common'
import { InventoryService } from '../../services/inventory.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { interval, Subscription, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-item-query',
	templateUrl: './item-query.component.html',
	styleUrls: ['./item-query.component.scss']
})
export class ItemQueryComponent implements OnInit {

	@Input() stockid;
	@ViewChild('itemsearch') customersearch: ElementRef;
	@ViewChild('itemdetailsModal') itemDetailsREf: ElementRef;
	itemSearchControl = new UntypedFormControl('');
	categoryControl = new UntypedFormControl('');
	productLineControl = new UntypedFormControl('');
	searching = false;
	searchfield = [];
	items: any = [];
	config: any;
	allitems: any = [];
	header: any = [];
	results: any = [];
	inventorysearching: any;
	itemsearchsubscription: any;
	categories: any = false;
	productlines: any = false;
	user: any = false;
	item_details: any = false;
	include_disabled = new UntypedFormControl(false);
	headercolumns: any = [
		'stockid',
		'lineid',
		'description',
		'qoh',
		'cost',
		'price',
		'supplierno',
	];

	selectedimage: string = '';

	color: any = 'blue'
	dataSource: MatTableDataSource < any > ;
	private sort: MatSort;
	running: any = false;
	itemdetails: any = false;
	editing = {
		settings: false,
		extra: false,
		descriptions: false,
		attributes: false,
		images: false,
		prices: false,
		stock: false,
		purchasing: false,
	}

	isEditingAvgCost = false;
	editingAvgCostValue: number;

	dataObs: Observable < any > ;
	items_per_page = [25, 50, 100, 500];
	pagesizedefault = 100;
	salestypes: any = false;
	loadingitem: any = false;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {

		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}

	constructor(private datePipe: DatePipe,private modalService: NgbModal, private globalsService: GlobalsService, private _changeDetectorRef: ChangeDetectorRef,private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, public router: Router, private location: Location, public inventoryService: InventoryService, public orderService: OrdersService, private globalSearchService: GlobalSearchService) {
		this.inventoryService.getItemCategories().subscribe(c => {
			this.categories = c
		});
		this.inventoryService.getItemProductlines().subscribe(p => {
			this.productlines = p
		})
		this.globalSearchService.userData.subscribe(r => {
			this.user = r;
		});

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.color = this.globalSearchService.getColor();

		this.globalSearchService.all_system_types.subscribe( (r:any) => {
			if(!r) {
				this.globalsService.getTypes().subscribe(async (results: any) => {
					this.salestypes = results.salestypes;
				});
			} else {
				this.salestypes = r.salestypes;
			}
		});
	}

	getTotalQoh(itemqoh: any) {
		let total_qoh = 0;
		if (itemqoh) {
			itemqoh.forEach(q => {
				if (q.loccode == this.user.user.defaultlocation.loccode) {
					total_qoh += parseFloat(q.quantity);
				}
			});
		}
		return total_qoh;
	}

	ngOnInit(): void {
		this.searching = false;
		this.setPagination([])
	}

	searchItems() {
		this.searching = true;

		if (this.inventorysearching) {
			this.inventorysearching.unsubscribe()
		}

		const search = {
			stockid: this.itemSearchControl.value,
			category: this.categoryControl.value,
			productlines: this.productLineControl.value,
			discontinued: this.include_disabled.value,
			price: 'default',
		}

		//leaving off until aa is on inventory && hairlines wanted it to be all shrewsbury
		// if(this.config.env.package == 'beauty'){
		// 	search['warehouse'] = this.user.user.defaultlocation.loccode;
		// }

		this.inventorysearching = this.inventoryService.lookup(search).subscribe((items: any) => {
			this.allitems = items;
			if(items.length === 1) {
				this.loadItem(items[0].stockid)
			}
			this.searching = false;
			this.setPagination(items);
		});
	}


	loadItem(stockid: any) {
		this.loadingitem = true;
		this.inventoryService.loadItem(stockid).subscribe((results: any) => {
			this.itemdetails = results;
			this.loadingitem = false;
		});
	}
	formatDate(date: string): string {
		return this.datePipe.transform(date, 'shortDate');
	}
	getFilteredItemStock() {
		// Initialize an array to hold the final filtered items
		const filteredItems = [];

		// Loop through all warehouses to ensure they are included
		this.itemdetails.itemstock.forEach(item => {
			const bins = this.itemdetails.bins[item.loccode];
			let includeItem = false;

			if (bins) {
				// Calculate bin quantities and check conditions
				const binQuantities = bins.map(loc => Number(loc.quantity));
				const hasPositiveAndNegative = binQuantities.some(q => q > 0) && binQuantities.some(q => q < 0);
				let defaultBin = bins.find(bin => bin.defaultbin === '1') || bins.find(bin => Number(bin.quantity) === 0);

				// If conditions are not met, set item quantity to zero and keep only the default or empty bin
				if (!hasPositiveAndNegative) {
					includeItem = true;
				} else {
					// Set item quantity to zero
					item.quantity = "0";
					includeItem = true; // Ensure the item is still included
					// If there's no valid default or empty bin, select the first available bin
					if (!defaultBin) {
						defaultBin = bins[0];
					}
				}

				// Assign the default or empty bin and filter out other bins
				if (defaultBin) {
					item.defaultBin = defaultBin.bin;
					item.quantity = Number(defaultBin.quantity) === 0 ? "0" : item.quantity;
				}

				// Clear the bins array and include only the selected bin in the final item
				this.itemdetails.bins[item.loccode] = [defaultBin];
			} else {
				// Include the item if there are no bins
				includeItem = true;
			}

			// Include the item in the final results if necessary
			if (includeItem) {
				filteredItems.push(item);
			}
		});

		return filteredItems;
	}

	getPriceForKey(key: string): number {
		if (this.itemdetails.item.special) {
			if (this.itemdetails.item.special.prices) {
				if (this.itemdetails.item.special.prices.hasOwnProperty(key)) {
					const price = this.itemdetails.item.special.prices[key];

					if (price) {
						return this.financial(price);
					}
				}
			}
		}
		return 0;
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	getGp(price, cost) {
		const priceminuscost = (this.financial(price) - this.financial(cost)) * 100;
		if (this.financial(price) != 0) {
			return (this.financial(priceminuscost / this.financial(price)));
		} else {
			return 0.00;
		}
	}

	getFirstPriceValue(prices: {
		[key: string]: any
	}): any {
		const keys = Object.keys(prices);
		if (keys.length === 0) {
			return 0;
		}
		const firstKey = keys[0];
		return prices[firstKey];
	}

	changeMainImage(image: any) {
		if (this.itemdetails.item) {
			this.itemdetails.item.full_size_image = image.image;
			this.selectedimage = image.file;
		}
	}

	clearForm() {
		this.itemSearchControl.setValue('');
		this.allitems = [];
		this.items = [];
		this.searching = false;
	}

	back(): void {
		this.location.back();
	}

	viewItem(row: any) {
		this.allitems = [];
		this.items = [];
		this.itemSearchControl.setValue('');
		this.router.navigate(['/inventory/view/' + row.stockid])
	}

	setPagination(tableData) {
		this.dataSource = new MatTableDataSource < any > (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();
	}

	openModal(content, item) {

		this.item_details = item;
		this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
			this.item_details = false;
		});
	}


	announceSortChange(input: any) {

	}

	toHTML(input) : any {
		return new DOMParser().parseFromString(input, 'text/html').documentElement.textContent;
	}

}
