<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }}">
						<h4 class="card-title" id="headof"><i class="material-icons" (click)="back()">arrow_left</i> Sales Tax Compressed</h4>

					</div>
					<div class="card-body">


						<form [formGroup]="formGroup" *ngIf="formGroup" >
							<div class="row">
								<div class="col-md-2">
									<mat-form-field appearance="outline" class="ml-2 mr-2">
										<mat-label>Tax Authorities</mat-label>
										<mat-select formControlName="from_tax" (selectionChange)="updateToOptions($event.value)" multiple appSelectAll>
											<mat-option *ngFor="let t of from_taxauth" [value]="t.taxgroupid">
												{{t.taxgroupdescription }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field appearance="fill">
								  	<mat-label>Date Range</mat-label>
								  	<mat-date-range-input [rangePicker]="picker">
										<input matStartDate formControlName="fromdate" placeholder="Start date">
										<input matEndDate formControlName="todate" placeholder="End date">
								  	</mat-date-range-input>
								  	<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
								  	<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
								  	<mat-date-range-picker #picker></mat-date-range-picker>
									</mat-form-field>
								</div>
								<div class="col-md-2 d-none">
									<mat-form-field appearance="outline" class="ml-2 mr-2">
										<mat-label>From</mat-label>
										<mat-select formControlName="fromperiod" (selectionChange)="updateToPeriodOptions($event.value)">
											<mat-option *ngFor="let p of from_period_options" [value]="p.periodno">
												{{ p.lastdate_in_period }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-2 d-none">
									<mat-form-field appearance="outline" class="ml-2 mr-2">
										<mat-label>To</mat-label>
										<mat-select formControlName="toperiod">
											<mat-option *ngFor="let p of to_period_options" [value]="p.periodno">
												{{ p.lastdate_in_period }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field appearance="outline" class="ml-2 mr-2">
										<mat-label>Detail/Summary</mat-label>
										<mat-select formControlName="details_sumamry">
											<mat-option *ngFor="let d of details_summary" [value]="d.value">
												{{ d.label }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="col-md-6 text-left">
									<ng-container *ngIf="group_totals ">

										<button class="btn btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
										&nbsp;&nbsp;
										<button class="btn btn-danger" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>

										<span *ngIf="exporting">
											<svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
													<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
												</circle>
											</svg>
										</span>

									<!--
										<a href="{{pdflink}}" target="_blank"><button mat-raised-button><i class="fa fa-file-pdf-o"></i> PDF</button></a>
										&nbsp;&nbsp;
										<a href="{{excellink}}" target="_blank"><button mat-raised-button><i class="fa fa-file-excel-o"></i> XLS </button></a>
									-->
									</ng-container>
								</div>

								<div class="col-md-6 text-right">
									<button mat-raised-button color="accent" (click)="getTaxReport()">Run</button>
									&nbsp;

								</div>
							</div>
						</form>
						<div class="row">
							<div class="col-md-12 text-center" *ngIf="loading">
								<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
									</circle>
								</svg>
							</div>
						</div>
						<ng-container *ngIf="group_totals">
							<div class="" #print_table>
							  <h3>{{ title }}</h3>
							  <table class="table table-fixed table-striped table-hover table-fluid">
								<thead class="thead-dark">
								  <tr>
									<th class="title text-left sticky-header">Title</th>
									<th class="title text-right sticky-header">Rate</th>
									<th *ngIf="formGroup.get('details_sumamry').value == 'D'" class="sticky-header"></th>
									<th *ngIf="formGroup.get('details_sumamry').value == 'D'" class="sticky-header"></th>
									<th *ngIf="formGroup.get('details_sumamry').value == 'D'" class="sticky-header"></th>
									<th class="text-right sticky-header">Sales Qty</th>
									<th class="text-right sticky-header">Taxable Sales</th>
									<th class="text-right sticky-header">Sales Tax</th>
									<th class="text-right sticky-header">NonTaxable Sales</th>
									<th class="text-right sticky-header">Sales Net</th>
									<th class="text-right sticky-header">Discount</th>
									<th class="text-right sticky-header">Sales Total</th>
									<th class="text-right sticky-header" *ngIf="config.env.package == 'beauty'">Taxable Freight</th>
									<th class="text-right sticky-header" *ngIf="config.env.package == 'beauty'">NonTaxable Freight</th>
								  </tr>
								</thead>
								<tbody>
								  <ng-container *ngFor="let group of group_totals; last as isLast">
									<tr>
									  <td class="title text-left">{{ group.title }}</td>
									  <td class="text-right">{{ group.rate }}</td>
									  <td *ngIf="formGroup.get('details_sumamry').value == 'D'"></td>
									  <td *ngIf="formGroup.get('details_sumamry').value == 'D'"></td>
									  <td *ngIf="formGroup.get('details_sumamry').value == 'D'"></td>
									  <td class="text-right">{{ group.sales_count }}</td>
									  <td class="text-right">{{ group.sales_taxable | currency }}</td>
									  <td class="text-right">{{ group.sales_tax | currency }}</td>
									  <td class="text-right">{{ group.sales_nontaxable | currency }}</td>
									  <td class="text-right">{{ group.sales_net | currency }}</td>
									  <td class="text-right">{{ group.discount | currency }}</td>
									  <td class="text-right">{{ group.sales_total | currency }}</td>
									  <td class="text-right" *ngIf="config.env.package == 'beauty'">{{ group.freight_taxable | currency }}</td>
									  <td class="text-right" *ngIf="config.env.package == 'beauty'">{{ group.freight_nontaxable | currency }}</td>
									</tr>
									<ng-container *ngIf="formGroup.get('details_sumamry').value == 'D'">
									  <tr>
										<th class="sticky-header" style="max-width:30px;">Date</th>
										<th class="sticky-header">Document#</th>
										<th class="sticky-header">Type</th>
										<th class="text-left sticky-header">Customer#</th>
										<th class="text-left sticky-header">Name</th>
										<th class="text-right sticky-header">AuthId</th>
										<th class="text-right sticky-header">Taxable</th>
										<th class="text-right sticky-header">NonTaxable</th>
										<th class="text-right sticky-header">Net Amount</th>
										<th class="text-right sticky-header">Tax</th>
										<th class="text-right sticky-header">Discount</th>
										<th class="text-right sticky-header">Total</th>
										<th class="text-right sticky-header" *ngIf="config.env.package == 'beauty'">Taxable Freight</th>
										<th class="text-right sticky-header" *ngIf="config.env.package == 'beauty'">NonTaxable Freight</th>
									  </tr>
									  <tr *ngFor="let account of group.details">
										<td>{{ account.trandate | date:'shortDate'}}</td>
										<td>{{ account.transno }}</td>
										<td>{{ account.typename }}</td>
										<td class="text-left">{{ account.debtorno }}.{{ account.branchcode }}</td>
										<td class="text-left">{{ account.name }}</td>
										<td class="text-left">{{ account.authid }}</td>
										<td class="text-left">{{ account.taxable | currency }}</td>
										<td class="text-left">{{ account.nontaxable | currency }}</td>
										<td class="text-right">{{ financial(account.net) | currency }}</td>
										<td class="text-right">{{ financial(account.tax) | currency }}</td>
										<td class="text-right">{{ financial(account.discount) | currency }}</td>
										<td class="text-right">{{ financial(account.total) | currency }}</td>
										<td class="text-right" *ngIf="config.env.package == 'beauty'">{{ account.freight_taxable | currency }}</td>
										<td class="text-right" *ngIf="config.env.package == 'beauty'">{{ account.freight_nontaxable | currency }}</td>
									  </tr>
									</ng-container>
									<tr *ngIf="isLast">
									  <td class="title">{{ totals.title }}</td>
									  <td class="text-right">{{ totals.rate }}</td>
									  <td class="text-right">{{ totals.sales_count }}</td>
									  <td class="text-right">{{ totals.sales_taxable | currency }}</td>
									  <td class="text-right">{{ totals.sales_tax | currency }}</td>
									  <td class="text-right">{{ totals.sales_nontaxable | currency }}</td>
									  <td class="text-right">{{ totals.sales_net | currency }}</td>
									  <td class="text-right">{{ totals.discount | currency }}</td>
									  <td class="text-right">{{ totals.sales_total | currency }}</td>
									  <td class="text-right" *ngIf="config.env.package == 'beauty'">{{ totals.freight_taxable | currency }}</td>
									  <td class="text-right" *ngIf="config.env.package == 'beauty'">{{ totals.freight_nontaxable | currency }}</td>
									</tr>
								  </ng-container>
								</tbody>
							  </table>
							</div>

						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
