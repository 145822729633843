import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common'

import { OrdersService } from '../../../services/orders.service';
import { CustomerService } from '../../../services/customer.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { PrintService } from '../../../services/print.service';
import { UsersService } from '../../../services/users.service';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-adjustment',
  templateUrl: './adjustment.component.html',
  styleUrls: ['./adjustment.component.scss']
})
export class AdjustmentComponent {
	@Input() transaction: any = [];

	constructor(private usersService: UsersService, private printService: PrintService, private route: ActivatedRoute, public ordersService: OrdersService, public globalSearchService: GlobalSearchService, public router: Router, public customerService: CustomerService, private modalService: NgbModal, private fb: UntypedFormBuilder, private globalsService: GlobalsService) {

	}

	ngOnInit(): void {
		this.customerService.getAdjustmentData(this.transaction).subscribe( r=> {

		});
	}
}
