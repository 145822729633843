<ng-template #itemdetailsModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title"><b>{{ item_details.stockid }}</b><span [innerHTML]="item_details.name"></span> &nbsp;&nbsp;&nbsp;
			<a [routerLink]="'/inventory/view/'+item_details.stockid"><i class="pointer fa fa-edit"></i></a>
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<mat-card class="tire-card " *ngIf="item_details">
				<mat-card-title-group>
					<mat-card-title>
						<h3 [innerHTML]="item_details.descriptions.name"></h3>
						<section class="section-small" [innerHTML]="toHTML(item_details.descriptions.description)"></section>
					</mat-card-title>
					<div class="tire-image">
						<div class="tire-image-container" *ngIf="item_details.image_large">
							<img mat-card-lg-image src="{{ item_details.image_large }}" class="img img-responsive large-thumb" lazy>
						</div>
					</div>
				</mat-card-title-group>
				<mat-card-content class="padding-0">
					<mat-tab-group #tabPanel mat-align-tabs="start" color="accent">
						<mat-tab label="Overview">
							<div class="row">
								<div class="col-md-6">
									Features:<br>
									<span [innerHTML]="toHTML(item_details.descriptions.features)"></span>
								</div>
								<div class="col-md-6">
									Benefits:<br>
									<span [innerHTML]="toHTML(item_details.descriptions.benefits)"></span>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Attributes">
							<div class="row">
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Specs">
							<!-- <mat-form-field appearance="outline" *ngIf="!addingattribute" class="text-right">
								<mat-label>Search Attributes</mat-label>
								<input type="text" class="text-right m-0 p-0" matInput (input)="searchAttributes($event.target.value)" />
							</mat-form-field> -->

							<div class="row">
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
					</mat-tab-group>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="card">
			<div class="card-header card-header-{{color}}-6">
				<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Inventory Query </h4>
				<p class="card-category">Scan or Type</p>
			</div>
			<div class="card-body">
				<div class="digital-add needs-validation">
							<div class="row">
								<div class="col-md-2 mb-0 mt-4">
									<mat-slide-toggle [formControl]="include_disabled">Include Discontinued</mat-slide-toggle>
								</div>

								<div class="col-md-2">
									<mat-form-field appearance="outline">
										<mat-label>Category</mat-label>
										<mat-select [formControl]="categoryControl" multiple="true">
											<!--<mat-option [value]="''">All</mat-option> -->
											<mat-option *ngFor="let c of categories" [value]="c.categoryid">
												{{ c.categorydescription }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field appearance="outline">
										<mat-label>Product Line</mat-label>
										<mat-select [formControl]="productLineControl" multiple="true">
											<mat-option *ngFor="let p of productlines" [value]="p.line_field">
												{{ p.line_description }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field appearance="outline" class="smaller-font-form notop text-right" color="accent">
										<mat-spinner matPrefix diameter="30" *ngIf="searching"></mat-spinner>
										<span matPrefix *ngIf="allitems.length && !searching">
											<button mat-icon-button class="p-0" (click)="clearForm()">
												<mat-icon class="p-0">clear</mat-icon>
											</button>
										</span>
										<input #itemSearch matInput [formControl]="itemSearchControl" placeholder="Search By PartNo/Description..." autofocus="true" class="w-100 m-0 p-0 notop text-right" (input)="searchItems()" (keyup.enter)="searchItems()">
										<span matSuffix>
											<button mat-icon-button color="white" (click)="searchItems()">
												<mat-icon class="p-0">search</mat-icon>
											</button>
										</span>
									</mat-form-field>
								</div>
							</div>
						</div>


				<div class="col-md-12" id="payrep" *ngIf="!running" >
					<ng-container>
						<mat-paginator
						  [pageSizeOptions]="items_per_page"
						  [pageSize]="pagesizedefault"
						></mat-paginator>

						<div  class="table" >
						<div class="bg-white mt-2 rounded">


							<div id="receipts" >
								<!-- <h4 class="mt-2 mb-4 noprint">{{ title }} {{ getLocationName() }}</h4> -->
								<table id="rcptTable" name="rcptTable" class="table compact table-sm table-fixed collapsed" *ngIf="allitems && allitems.length">
									<thead class="thead-dark">
										<tr>
											<th class="text-center"></th>
											<th class="text-left">Item#</th>
											<th class="text-left">Category</th>
											<th class="text-left">Line</th>
											<th class="text-left">Item</th>
											<th class="text-center">QOH</th>
											<th class="text-center">Price</th>

											<!-- <ng-container *ngFor="let p of salestypes">
												<th class="text-right" *ngIf="p.typeabbrev != 'O' && p.typeabbrev !='M'">{{ p.typeabbrev }}: {{ p.sales_type }}</th>
											</ng-container> -->
										</tr>
									</thead>
									<tbody>
										<ng-container *ngFor="let item of dataObs | async">
										<tr>
											<td class="text-center"><img src="{{item.thumb}}" class="img img-fluid"></td>
											<td class="text-left" >
												<!-- <a [routerLink]="'/inventory/view/'+item.stockid">{{ item.stockid }}</a> -->
												<a href="#" (click)="$event.preventDefault();openModal(itemdetailsModal, item)">{{ item.stockid }}</a>
											</td>
											<td class="text-left">{{ item.categoryid }}</td>
											<td class="text-left">{{ item.lineid }}</td>
											<td class="text-left">{{ item.description }}</td>
											<td class="text-center"> {{ item.qty }} </td>
											<td class="text-center"> {{ item.price }} </td>

											<!-- <td class="text-right"  *ngFor="let z of item.allprices">
												{{z.price | currency }}
											</td> -->
										</tr>
										</ng-container>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					</ng-container>
					<ng-container *ngIf="allitems && !allitems.length">
						<h4 class="text-center">No Items</h4>
					</ng-container>
					<ng-container *ngIf="loadingitem">
						<div class="ml-auto mr-auto text-center">
							<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</div>
					</ng-container>
					<div class="row mt-2 p-0 m-0" *ngIf="itemdetails && itemdetails.success && !loadingitem">
						<div class="card col-md-4 card-editor">
							<div class="card-header card-header-icon pointer card-header-rose">
								<div class="card-icon">
									<i class="material-icons">settings</i>
								</div>
								<h4 class="card-title  ">Setup:</h4>
							</div>
							<div class="card-body">
								<ul class="entry-list p-0 m-0">
									<li>
										<div class="spec-label bg-white">
											Description:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.description }}
										</div>
									</li>
									<li>
										<div class="spec-label bg-white">
											Brand
										</div>
										<div class="spec-value">
											{{ itemdetails.brand }}
										</div>
									</li>
									<li>
										<div class="spec-label bg-white">
											Category:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.categorydescription }}
										</div>
									</li>
									<li>
										<div class="spec-label bg-white">
											Product Line:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.lineid }} {{ itemdetails.item.linedesc }}
										</div>
									</li>
									<li>
										<div class="spec-label bg-white">
											Product Type:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.mbflag_dsp }}
										</div>
									</li>
									<li>
										<div class="spec-label bg-white">
											GL Class:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.glclass }}
										</div>
									</li>
									<li>
										<div class="spec-label bg-white">
											Tax Category:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.taxcategory }}
										</div>
									</li>
									<li>
										<div class="spec-label bg-white">
											BOM:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.hasbom }}
										</div>
									</li>
								</ul>

							</div>
						</div>

						<div class="card col-md-4 card-editor" [ngClass]="itemdetails.item.discontinued  == '1' ? 'border-danger border' : ''">
							<div class="card-header card-header-icon pointer card-header-success">
								<div class="card-icon" >
									<i class="material-icons">line_weight</i>
								</div>
								<h4 class="card-title  ">Extra:</h4>
							</div>
							<div class="card-body ">
								<ul class="entry-list p-0 m-0" >
									<li>
										<div class="spec-label bg-white">
											Created:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.created }}
										</div>
									</li>
									<li *ngIf="config.env.package == 'tires'">
										<div class="spec-label bg-white">
											Fee: (FET)
										</div>
										<div class="spec-value">
											<ng-container *ngIf="itemdetails.item.additionalfee">
												{{ itemdetails.item.additionalfee | currency }}
											</ng-container>
											<ng-container *ngIf="!itemdetails.item.additionalfee">
												$0.00
											</ng-container>
										</div>
									</li>

									<li>
										<div class="spec-label bg-white">
											*Barcode:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.barcode }}
										</div>
									</li>
									<li *ngIf="config.env.package != 'tires'">
										<div class="spec-label bg-white">
											PanSize:
										</div>
										<div class="spec-value">
											<ng-container *ngIf="itemdetails.pansize">
												{{ itemdetails.pansize}}
											</ng-container>
											<ng-container *ngIf="!itemdetails.pansize">
												N/A
											</ng-container>
										</div>
									</li>
									<!--<li>
										<div class="spec-label bg-white">
											EOQ:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.eoq }}
										</div>
									</li>-->
									<li>
										<div class="spec-label bg-white">
											Controlled:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.controlled_dsp }}
										</div>
									</li>
									<li>
										<div class="spec-label bg-white">
											Units:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.units }}
										</div>
									</li>
									<li>
										<div class="spec-label bg-white">
											DWO:
										</div>
										<div class="spec-value" [ngClass]="itemdetails.item.dwo ? 'bg-warning' : ''">
											{{ itemdetails.item.dwo_dsp }}
										</div>
									</li>
									<li>
										<div class="spec-label bg-white">
											Discontinued:
										</div>
										<div class="spec-value" [ngClass]="itemdetails.item.discontinued ? 'bg-danger text-white' : ''">
											{{ itemdetails.item.discontinued_dsp }}
										</div>
									</li>
									<li>
										<div class="spec-label bg-white">
											Web Enabled:
										</div>
										<div class="spec-value" [ngClass]="!itemdetails.item.enabled ? 'bg-danger text-white' : ''">
											{{ itemdetails.item.enabled_dsp }}
										</div>
									</li>
									<li>
										<div class="spec-label bg-white">
											Commissionable:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.commissionable_dsp }}
										</div>
									</li>
									<li *ngIf="itemdetails.item.commissionable">
										<div class="spec-label bg-white">
											Commission Rate:
										</div>
										<div class="spec-value">
											{{ itemdetails.item.commpct }}%
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div class="card col-md-4 card-editor">
							<div class="card-header card-header-icon pointer card-header-danger">
								<div class="card-icon" >
									<i class="material-icons">image</i>
								</div>
								<h4 class="card-title  ">Images:</h4>
							</div>
							<div class="card-body text-center ">

								<div class="product-gallery" *ngIf="!editing.images">
									<div class="main-image-container">
										<div class="image-wrapper">
											<img [src]="itemdetails.item.full_size_image" class="img img-fluid img-responsive thumbnail item-image ml-auto mr-auto">
										</div>
									</div>
									<div class="thumbnail-container" *ngIf="itemdetails.item.images && itemdetails.item.images.length > 1">
										<ng-container *ngFor="let thu of itemdetails.item.images">
											<div class="thumbnail" (click)="changeMainImage(thu)">
												<div class="main-image-container">
													<div class="image-wrapper">
														<img [src]="thu.thumb" alt="{{thu.alt}}">
													</div>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
						<div class="card col-md-4 card-editor">
							<div class="card-header card-header-icon pointer card-header-warning">
								<div class="card-icon" >
									<i class="material-icons">payment</i>
								</div>
								<h4 class="card-title  ">Prices:</h4>
							</div>
							<div class="card-body m-0 p-0 mt-3 ">

								<ul class="entry-list" >
									<li *ngIf="itemdetails.item.special && itemdetails.item.special.prices" class="pink-color">
										<!-- todo not do this -->
										<b>ON SPECIAL</b>
										<!-- &nbsp;&nbsp;{{ getFirstPriceValue(itemdetails.item.special.prices) | currency  }} -->
									</li>
								</ul>
								<mat-divider></mat-divider>
								<table class="table table-fluid w-100 smaller-table">
									<thead>
										<tr>
											<th>Type</th>
											<th>Price</th>
											<th *ngIf="config.plm">PLM</th>
											<ng-container *ngIf="config.env.package !== 'tires'">
												<th>Start Date</th>
												<th>End Date</th>
												<th>Currency</th>
											</ng-container>
											<th>GP</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let p of itemdetails.allpricing" [ngClass]="getPriceForKey(p.typeabbrev) ? 'pink-border': ''">
											<td class="spec-label bg-white">{{ p.typeabbrev }} {{ p.sales_type }}</td>
											<td>
												<ng-container *ngIf="getPriceForKey(p.typeabbrev) as specialPrice; else originalPrice">
													<span class="original-price" title="Original Price">{{ p.price | currency }}</span>
													<span class="special-price">{{ specialPrice | currency }}</span>
												</ng-container>
												<ng-template #originalPrice>
													<span class="price">{{ p.price | currency }}</span>
												</ng-template>
											</td>
											<td *ngIf="config.plm">
												{{ getPlmMarkup(p.typeabbrev) }}
											</td>
											<ng-container *ngIf="config.env.package != 'tires'">
												<td>{{ formatDate(p.startdate) }}</td>
												<td>{{ formatDate(p.enddate) }}</td>
												<td>{{ p.currabrev }}</td>
											</ng-container>
											<td>{{ getGp(p.price, itemdetails.item.actualcost) }}%</td>
										</tr>
									</tbody>
								</table>
								<table class="table table-fluid w-100 smaller-table" *ngIf="itemdetails.price_break">
								<thead>
								<tr><th colspan="5" style="text-center">Qty Breaks</th></tr>
								<tr><th>Price Lv</th><th>Qty</th><th>Price</th><th>Start</th><th>End</th></tr>
								</thead>
								<tbody>
								<tr *ngFor="let pb of itemdetails.price_break">
								<td class="spec-label bg-white">{{pb.salestype}}</td><td>{{pb.quantitybreak}}</td><td>{{pb.price | currency }}</td>
								<td class="spec-label bg-white">{{ formatDate(pb.startdate) }}</td>
												<td>{{ formatDate(pb.enddate) }}</td>
								</tr>
								</tbody>
								</table>


								<!-- <ul class="entry-list" *ngIf="!editing.prices">
									<li *ngFor="let p of itemdetails.allpricing">
										<div class="spec-label bg-white">
											{{ p.typeabbrev }} {{ p.sales_type }}
										</div>
										<div class="spec-value">
											<span *ngIf="getSpecial(p.typeabbrev)" class="text-danger">
												{{ getSpecial(p.typeabbrev) | currency}}
											</span>
											<span *ngIf="!getSpecial(p.typeabbrev)">
												{{ p.price | currency}}
											</span>

											<ng-container *ngIf="!hasspecial">
											 <span class="pull-right" [ngClass]="{'text-danger': getGp(p.price, itemdetails.item.cost) < 0 }">
												 {{ getPlmMarkup(p.typeabbrev) }}
												 <i>(GP:{{ getGp(p.price, itemdetails.item.cost)}} %)</i></span>
											</ng-container>
										</div>
									</li>
								</ul> -->
							</div>
						</div>
						<div class="card col-md-4 card-editor">
							<div class="card-header card-header-icon pointer card-header-info">
								<div class="card-icon">
									<i class="material-icons">category</i>
								</div>
								<h4 class="card-title  ">Inventory:</h4>
							</div>
							<div class="card-body ml-0 mr-0 pl-0 pr-0">


								<ul class="inventory-list p-0 m-0 item-bins" >
									<ng-container *ngFor="let item of getFilteredItemStock()">
										<li>
											<div class="inventory-spec-label dashed"><b>{{ item.locationname }}:</b></div>
											<div class="inventory-spec-value dashed">
												<b>{{ item.quantity }}</b>
											</div>
										</li>
										<ng-container *ngIf="itemdetails.bins">
											<ng-container *ngFor="let loc of itemdetails.bins[item.loccode]">
												<ng-container *ngIf="loc">
													<li *ngIf="loc.quantity != '0' || loc.defaultbin === '1'">
														<div class="inventory-spec-label bg-white">
															<span *ngIf="loc.bin == ''"><i>N/A</i></span>
															<span *ngIf="loc.bin != ''"><i>{{ loc.bin }}</i></span>
														</div>
														<div class="inventory-spec-value">
															&nbsp;({{ loc.quantity }})
														</div>
													</li>
												</ng-container>
											</ng-container>
										</ng-container>
									</ng-container>

									<ng-container *ngIf="itemdetails.remote_inventory && itemdetails.remote_inventory.length">
										<mat-divider></mat-divider>
										<li *ngFor="let remote of itemdetails.remote_inventory">
											<div class="inventory-spec-label bg-white">{{ remote.feedlabel }}:</div>
											<div class="inventory-spec-value">{{ remote.qty }}</div>
										</li>
									</ng-container>
								</ul>



								<div class="table">
									<table class="table table-sm">
										<thead>
											<tr>
												<th class="text-center">MIN/MAX:</th>
												<th class="text-center">COMMITTED</th>
												<th class="text-center">ON ORDER</th>
											</tr>
										</thead>
										<tbody>
											<ng-container *ngIf="itemdetails.item.minmax">
												<tr>
													<td class="text-center">
														<span *ngIf="itemdetails.item.minmax.minimum">
															{{ itemdetails.item.minmax.minimum }}
														</span>
														<span *ngIf="!itemdetails.item.minmax.minimum">
															N/A
														</span>
														/
														<span *ngIf="itemdetails.item.minmax.maximum">
															{{ itemdetails.item.minmax.maximum }}
														</span>
														<span *ngIf="!itemdetails.item.minmax.maximum">
															N/A
														</span>
													</td>
													<td class="text-center">{{ itemdetails.itemdemand }}</td>
													<td class="text-center">{{ itemdetails.openpos }}</td>
												</tr>
											</ng-container>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="card col-md-4 card-editor">
							<div class="card-header card-header-icon pointer card-header-secondary">
								<div class="card-icon" >
									<i class="material-icons">storefront</i>
								</div>
								<h4 class="card-title  ">Purchasing:</h4>
							</div>
							<div class="card-body ml-0 mr-0 pl-0 pr-0">
								<mat-divider></mat-divider>
								<ul class="purchasing-entry-list p-0 m-0 mt-2" >
									<ng-container *ngIf="user.user.viewcost">
										<li *ngIf="config.plm">
											<div class="purchasing-spec-label dark">
												<span [ngClass]="itemdetails.item.hasoveridemark ? 'badge badge-warning' : ''"><b>MARKUP COST:</b></span>
											</div>
											<div class="purchasing-spec-value dark">
												{{ itemdetails.item.actualcost | currency }}
											</div>
										</li>
										<li>
											<div class="purchasing-spec-label">
												AVG COST:
											</div>
											<div class="purchasing-spec-value" >
												{{ itemdetails.item.cost | currency }}
											</div>
										</li>
										<li>
											<div class="purchasing-spec-label bg-white">
												LAST COST
											</div>
											<div class="purchasing-spec-value">
												<span *ngIf="itemdetails.lastpoprice">{{ itemdetails.lastpoprice | currency }}</span>
												<span *ngIf="!itemdetails.lastpoprice">N/A</span>
												<span *ngIf="itemdetails.lastpurch != 'N/A'"> ({{ itemdetails.lastpurchqty }} @ {{ itemdetails.lastpurch }} )</span>
											</div>
										</li>
										<li>
											<div class="purchasing-spec-label">
												PURCHASE PRICE:
											</div>
											<div class="purchasing-spec-value">
												{{ itemdetails.primarypurchase.price | currency }} {{ itemdetails.primarypurchase.suppliersuom }}
											</div>
										</li>
										<li>
											<div class="purchasing-spec-label bg-white">
												PRIMARY SUPPLIER:
											</div>
											<div class="purchasing-spec-value">
												{{ itemdetails.primarypurchase.supplierno }} : {{ itemdetails.primarypurchase.suppname }}
											</div>
										</li>
										<li>
											<div class="purchasing-spec-label">
												PREV AVG COST:
											</div>
											<div class="purchasing-spec-value">
												{{ itemdetails.item.lastcost | currency }}
											</div>
										</li>
									</ng-container>
									<li>
										<div class="purchasing-spec-label bg-white">
											LAST SALE
										</div>
										<div class="purchasing-spec-value">
											{{ itemdetails.lastordered }}
										</div>
									</li>
								</ul>


							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
