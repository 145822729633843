import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';

@Injectable({
	providedIn: 'root'
})

export class InventoryService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	user: any = [];
	package: any = '';

	constructor(private http: HttpClient, private httpClient: HttpClient, private _configSvc: RuntimeConfigLoaderService, private globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
		this.globalSearchService.user.subscribe((result: any) => {
			this.user = result;
		});
	}
	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public updateMM(data) {
		const method = 'inventory/mixandmatch/update'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public saveMM(data) {
		const method = 'inventory/mixandmatch/save'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public removeMM(data) {
		const method = 'inventory/mixandmatch/delete'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public createMM(data) {
		const method = 'inventory/mixandmatch/save'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getMixItems(data: any = []) {
		const method = 'inventory/mixandmatch/getitems';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getMix(data: any = []) {
		const method = 'inventory/mixandmatch/getmix';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getMixAndMatch(data: any = []) {
		const method = 'inventory/mixandmatch/list';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getPromo(data: any = []) {
		const method = 'inventory/promos';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getPromos(data: any = []) {
		const method = 'inventory/promos/list';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateMinMax(data: any) {
		const method = 'inventory/updateminmax';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public changeStockId(data: any) {
		const method = 'globals/inventory/updatestockids';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public setAvgCost(data: any) {
		const method = 'inventory/updateavg';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public setActualCost(data: any) {
		const method = 'inventory/updateactual';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateAttribute(data: any) {
		const method = 'inventory/updateattribute';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public addAttribute(data: any) {
		const method = 'inventory/addattribute';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public saveCounts(data: any) {
		const method = 'inventory/stockcounts/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public printCountSheets(data: any) {
		const urlvar = $.param(data);
		const method = 'inventory/stockcounts/printsheets&' + urlvar;
		return this.setEndPoint(method);
	}

	public updateItemDescription(data: any) {
		const method = 'inventory/updateitemdescription';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeImage(data: any) {
		const method = 'inventory/removeimage';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public removeMainImage(data: any) {
		const method = 'inventory/removeimage&main=true';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateItemFeatures(data: any) {
		const method = 'inventory/updateitemfeatures';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateItemBenefits(data: any) {
		const method = 'inventory/updateitembenefits';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}


	public closeCounts(data: any) {
		const method = 'inventory/stockcounts/complete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public reviewCounts(data: any) {
		const method = 'inventory/stockcounts/review';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getCurrentCounts(data: any) {
		const method = 'inventory/stockcounts/loadonly';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public createLoadSheets(data: any) {
		const method = 'inventory/stockcounts/create';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getInventoryValuation(data: any) {
		const method = 'inventory/valuation';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getItemHazards() {
		const method = 'globals/inventory/hazards';
		return this.http.get(this.setEndPoint(method));
	}

	public getItemRebates() {
		const method = 'globals/inventory/rebates';
		return this.http.get(this.setEndPoint(method));
	}

	public getItemManufacturer() {
		const method = 'globals/inventory/manufacturer';
		return this.http.get(this.setEndPoint(method));
	}


	public saveManufacturer(data: any) {
		const method = 'globals/inventory/savemanufacturer';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public removeManufacturer(data: any) {
		const method = 'globals/inventory/removemanufacturer';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public saveProductLine(data: any) {
		const method = 'globals/inventory/saveproductlines';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public mergeProductLines(data: any) {
		const method = 'globals/inventory/mergeproductlines';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public removeProductLine(data: any) {
		const method = 'globals/inventory/removeproductlines';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getItemProductlines() {
		const method = 'globals/inventory/productlines';
		return this.http.get(this.setEndPoint(method));
	}

	public getItemProductlineDetails() {
		const method = 'globals/inventory/productlinedetails';
		return this.http.get(this.setEndPoint(method));
	}
	public getGLClasses() {
		const method = 'globals/inventory/glclass';
		return this.http.get(this.setEndPoint(method));
	}

	public saveGLClass(data: any) {
		const method = 'globals/inventory/saveglclass';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public removeGLClass(data: any) {
		const method = 'globals/inventory/removeglclass';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getItemCategories() {
		const method = 'globals/inventory/stockcategories';
		return this.http.get(this.setEndPoint(method));
	}
	public getStockCatTypes() {
		const method = 'globals/inventory/stocktypes';
		return this.http.get(this.setEndPoint(method));
	}
	public saveCategory(data: any) {
		const method = 'globals/inventory/savecategories';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeCategory(data: any) {
		const method = 'globals/inventory/removecategories';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public librarySelect(data: any) {
		const method = 'inventory/librarysearch/select';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public librarySearch(data: any) {
		const method = 'inventory/librarysearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getBrands() {
		const method = 'inventory/properties/brands';
		return this.http.get(this.setEndPoint(method));
	}

	public addRecItem(data: any) {
		const method = 'oms/recitems/recany';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getMovements(data: any) {
		const method = 'inventory/history/movements';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeBrandPriorities(data: any) {
		const method = 'inventory/properties/brandprioritiesremove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateBrandPriorities(data: any) {
		const method = 'inventory/properties/brandprioritiesupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getBrandPriorities(id: any) {
		const method = 'inventory/properties/brandpriorities&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public getItemConfig(stockid: any) {
		const method = 'inventory/properties/config&stockid=' + stockid;
		return this.http.get(this.setEndPoint(method));
	}

	public getPurchaseHistory(stockid: any) {
		const method = 'inventory/history/purchasedata&stockid=' + stockid;
		return this.http.get(this.setEndPoint(method));
	}

	public getRemoteNewItems() {
		const method = 'remote/inventory/newitems';
		return this.http.get(this.setEndPoint(method));
	}
	public getMissingPurchaseData() {
		const method = 'inventory/dashboard/missingpurchase';
		return this.http.get(this.setEndPoint(method));
	}

	public getNegative() {
		const method = 'inventory/dashboard/negative';
		return this.http.get(this.setEndPoint(method));
	}

	public getZeroCost() {
		const method = 'inventory/dashboard/zerocost';
		return this.http.get(this.setEndPoint(method));
	}

	public getNeedLocation() {
		const method = 'inventory/dashboard/need';
		return this.http.get(this.setEndPoint(method));
	}

	public getMissingImages() {
		const method = 'inventory/dashboard/missingimages';
		return this.http.get(this.setEndPoint(method));
	}

	public uploadFile(formdata: any, stockid: any) {
		const method = 'inventory/updateimage&stockid=' + stockid;
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	public matchItems(data: any) {
		const method = 'inventory/pricebook/matchitems';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public uploadPriceBook(formdata: any) {
		const method = 'inventory/pricebook/upload';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	public getBatchUpload() {
		const method = 'inventory/batchupload';
		return this.http.post(this.setEndPoint(method), {}, this.httpOptions).pipe(
			catchError(this.handleError(method, {}))
		);
	}

	filterPriceLevels(levels: any,categories: any) : Observable < any > {
		const method = 'inventory/pricellevels';
		return this.http.post(this.setEndPoint(method), {}, this.httpOptions).pipe(
			catchError(this.handleError(method, {}))
		);
	}

	getPriceLevels(): Observable < any > {
		const method = 'inventory/pricellevels';
		return this.http.post(this.setEndPoint(method), {}, this.httpOptions).pipe(
			catchError(this.handleError(method, {}))
		);
	}

	savePriceLevel(formdata: any): Observable < any > {
		const method = 'inventory/pricellevels/save';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	createPriceLevel(formdata: any): Observable < any > {
		const method = 'inventory/pricellevels/create';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	updatePriceLevel(formdata: any): Observable < any > {
		const method = 'inventory/pricellevels/update';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	deletePriceLevel(formdata: number): Observable < any > {
		const method = 'inventory/pricellevels/remove';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}


	public uploadInventory(formdata: any) {
		const method = 'inventory/batchupload/upload';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	public removeUpload(formdata: any) {
		const method = 'inventory/batchupload/remove';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	public prepUpload(formdata: any) {
		const method = 'inventory/batchupload/prep';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	public prepExportUpload(formdata: any) {
		const method = 'inventory/batchupload/prepexport';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}



	public processUpload(formdata: any) {
		const method = 'inventory/batchupload/process';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	public uploadSpecials(formdata: any) {
		const method = 'inventory/specials/upload';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}
	public sendItemSpecials(data: any) {
		const method = 'inventory/specials/batchupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public updatePrices(data: any) {
		const method = 'inventory/updateprices';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public updatePriceMatrix(data: any) {
		const method = 'inventory/updatepricematrix';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public removePrice(data: any) {
		const method = 'inventory/removeprice';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public updateStock(data: any) {
		const method = 'inventory/updatestock';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public updateItem(data: any) {

		const method = 'inventory/update';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public addItem(data: any) {
		const method = 'inventory/create';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getItemHistory(stockid: any) {
		const method = 'inventory/history&stockid=' + stockid;
		return this.http.get(this.setEndPoint(method));
	}

	public getItemHistoryByLocation(data: any) {
		const method = 'inventory/history';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getBrandProductlines(data: any) {
		const method = 'globals/inventory/productlinesbybrand';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getProductPrices(lineid: any) {
		const method = 'inventory/prices&lineid=' + lineid;
		return this.http.get(this.setEndPoint(method));
	}

	public getProductPriceMarkup(lineid: any, cost: any) {
		const method = 'inventory/prices&lineid=' + lineid + '&cost=' + cost;
		return this.http.get(this.setEndPoint(method));
	}

	public reverseMovements(id: any) {
		const method = 'inventory/reversebinhistory&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}
	public getBinMovements(data: any) {
		const method = 'inventory/binhistory';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getBinMovementDetails(id: any) {
		const method = 'inventory/binhistorydetails&bin=' + id;
		return this.http.get(this.setEndPoint(method));
	}
	public getItemBins(data: any) {
		const method = 'inventory/itembins';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public itemCleanSearch(keyword: any) {
		const method = 'inventory/rawsearch&keywords=' + encodeURIComponent(keyword);
		return this.http.get(this.setEndPoint(method));
	}

	public itemCleanSearchScan(keyword: any) {
		const method = 'inventory/rawscansearch&keywords=' + encodeURIComponent(keyword);
		return this.http.get(this.setEndPoint(method));
	}

	public addBarcodeClean(data: any) {
		const method = 'inventory/addbarcodeclean';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public addBarcode(data: any) {
		const method = 'inventory/addbarcode';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public removeBarcode(data: any) {
		const method = 'inventory/removebarcode';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public mergeBins(from: any, to: any) {
		const method = 'inventory/mergebins'
		const data = { from: from, to: to }
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	//different structure this needs undone
	public moveItemsErp(items: any, bin: any) {
		const method = 'inventory/moveitemserp'
		const data = { items: items, to: bin }
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public moveItemsToBin(items: any, bin: any) {
		const method = 'inventory/moveitemstobin'
		const data = { items: items, to: bin }
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getHistory(item) {
		const method = 'inventory/history&stockid=' + item;
		return this.http.get(this.setEndPoint(method));
	}
	public getBins() {
		const method = 'inventory/bins';
		return this.http.get(this.setEndPoint(method));
	}

	public getStorageBins() {
		const method = 'inventory/storagebins';
		return this.http.get(this.setEndPoint(method));
	}

	public updateStorageBin(data) {
		const method = 'inventory/updatestoragebin';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public changeBin(from: any, to: any) {
		const method = 'inventory/changebin'
		const data = { from: from, to: to }
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);

	}

	public getBinSearch(data: any) {
		const method = 'inventory/binlookup';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getBinItems(bin) {
		const method = 'inventory/binitems&bin=' + bin;
		return this.http.get(this.setEndPoint(method));
	}

	public addBin(data) {
		const method = 'inventory/addnewbin'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public addBinSimple(data) {
		const method = 'inventory/addnewbinsimple'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getItems() {
		const method = 'inventory/allitems';
		return this.http.get(this.setEndPoint(method));
	}

	public getAllItems() {
		const method = 'inventory/allitems';
		return this.http.get(this.setEndPoint(method));
	}

	public getItemsMissingBrand() {
		const method = 'inventory/missingbrand';
		return this.http.get(this.setEndPoint(method));
	}

	public loadItem(data) {
		const method = 'inventory/getitem&stockid=' + data;
		return this.http.get(this.setEndPoint(method));
	}


	public removeBin(bin: any) {
		const method = 'inventory/removebincode'
		const data = bin;
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public changeBinCode(bin: any) {
		const method = 'inventory/updatebincode'
		const data = bin;
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getItem(data) {
		const method = 'inventory/getitem'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	getItemByStockid(data) {
		const method = 'inventory/getitembystockid'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getSpecialsData() {
		const method = 'inventory/specials'
		return this.http.get(this.setEndPoint(method));
	}
	public getItemSpecial(data) {
		const method = 'inventory/specials/getspecial'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getStockItemSpecial(data) {
		const method = 'inventory/specials/getitemspecial'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public saveItemSpecial(data) {
		const method = 'inventory/specials/savespecial'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public removeItemSpecial(data) {
		const method = 'inventory/specials/removespecial'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getMfgs() {
		const method = 'inventory/getmfgs'
		return this.http.get(this.setEndPoint(method));
	}

	public getSupplierFromBrand(brand) {
		const method = 'inventory/getsupplierfrompbrand';
		return this.http.post(this.setEndPoint(method), brand, this.httpOptions).pipe(
			catchError(this.handleError('inventory', brand))
		);
	}

	public getSupplierFromProductLine(lineid) {
		const method = 'inventory/getsupplierfrompline&mid=' + lineid
		return this.http.get(this.setEndPoint(method));
	}

	public getMfgsFromProductLine(lineid) {
		const method = 'inventory/getmfgsfrompline&mid=' + lineid
		return this.http.get(this.setEndPoint(method));
	}

	public lookup(data) {
		const method = 'inventory'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public promolookup(data) {
		const method = 'inventory/promos/search'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public removePromo(data) {
		const method = 'inventory/promos/remove'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public savePromo(data) {
		const method = 'inventory/promos/save'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}



	public removePromoItem(data) {
		const method = 'inventory/promos/removefrompromo'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public addToPromo(data) {
		const method = 'inventory/promos/addtopromo'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public itemSearch(data) {
		const method = 'inventory/itemsearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public lookupPickingBarcode(data) {
		const method = 'inventory/barcodelookup'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public lookupBarcode(data) {
		const method = 'inventory/barcodelookup'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public explodeBom(data) {
		const method = 'inventory/explodebom';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getBreakdown(data) {
		const method = 'inventory/breakdown';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public postBreakdown(data) {
		const method = 'inventory/postbreakdown';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public renameBom(data) {
		const method = 'inventory/renamebom';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public updateBom(data) {
		const method = 'inventory/updatebom';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getTireInfo(data) {
		const method = 'inventory/gettireinfo';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public atdSingleSearch(data) {
		const method = 'inventory/singleatdsearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public tireinfoBrands(data) {
		const method = 'inventory/brandsfromtireinfo';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getSeasons() {
		const method = 'inventory/getseasons';
		return this.http.get(this.setEndPoint(method));
	}

	public getDesignations() {
		const method = 'inventory/getdesignations';
		return this.http.get(this.setEndPoint(method));
	}

	public tlibSingleSearch(data) {
		const method = 'inventory/singletlibsearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public mfiSingleSearch(data) {
		const method = 'inventory/singlemfisearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public submitAttributes(data) {
		const method = 'inventory/submitattributes';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public absoluteItemSearch(data) {
		const method = 'inventory/absoluteitemsearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public absoluteKitSearch(data) {
		const method = 'inventory/absolutekitsearch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public recdocReport(data) {
		const method = 'inventory/recdocreport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public creditItemsReport(data) {
		const method = 'inventory/credititemsreport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getPickHistory(data) {
		const method = 'inventory/getpickhistory';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getIdet(data) {
		const method = 'inventory/getidet';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public pickableReport(data) {
		const method = 'inventory/pickablereport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public needsReport(data) {
		const method = 'inventory/needsreport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public nsrbReport(data) {
		const method = 'inventory/nsrbreport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public adjustInventory(data) {
		const method = 'inventory/adjustment';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public cancelTransfer(data) {
		const method = 'inventory/transfercancel';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getTransfers(data) {
		const method = 'inventory/transfers';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getTransferData(data) {
		const method = 'inventory/transfers/edittransfer';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getTransferItems(data) {
		const method = 'inventory/transfers/getparts';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getTransferLocations() {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user === null) {
			///return false;
		} else {
			this.user = user;
		}

		const addon = (this.user.user) ? '&userid=' + this.user.user.userid : '';
		const method = 'inventory/transfers/locations&userid=' + addon;
		return this.http.get(this.setEndPoint(method));
	}
	public getLocationAddress(data) {
		const method = 'inventory/transfers/locaddr';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public createTransfer(data) {
		const method = 'inventory/transfers/addtransfer';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public releaseTransfer(data) {
		const method = 'inventory/transfers/release';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public receiveTransfer(data) {
		const method = 'inventory/transfers/receive';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public autoMoveTransfer(data) {
		const method = 'inventory/transfers/instant';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public addTransferLine(data) {
		const method = 'inventory/transfers/addline';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public removeTransferLine(data) {
		const method = 'inventory/transfers/removeline';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public updateTransferLine(data) {
		const method = 'inventory/transfers/updateline';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public updateTransferHeader(data) {
		const method = 'inventory/transfers/updateheader';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public addTransferLineRestock(data) {
		const method = 'inventory/transfers/addlinerestock';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getReStock(data: any) {
		const method = 'inventory/transfers/restock';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));
	}
	public getReStockWeekly(data: any) {
		const method = 'inventory/transfers/weeklyrestock';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));
	}

	public getSingleRestock(data: any) {
		const method = 'inventory/transfers/forcerestock';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));

	}

	public getTagAlongs(){
		const method = 'inventory/gettagalongs'
		return this.http.get(this.setEndPoint(method));
	}

	public top100ItemsReport(data: any) {
		const method = 'reports/tophundred';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}
	public cidReport(data: any) {
		const method = 'reports/cidreport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}
	public batchDisableProducts(data: any) {
		const method = 'inventory/batchdisable';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public batchDiscontinueProducts(data: any) {
		const method = 'inventory/batchdiscontinue';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public batchDisableBrand(data: any) {
		const method = 'inventory/branddisable';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public batchDiscontinueBrand(data: any) {
		const method = 'inventory/branddiscontinue';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getServiceLocation(data: any) {
		const method = 'inventory/transfers/servicelocation';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
		return this.http.get(this.setEndPoint(method));

	}
	public saveGroup(data: any) {
		const method = 'inventory/groups/savegroup';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getGroups() {
		const method = 'inventory/groups/getgroups';
		return this.http.get(this.setEndPoint(method));
	}

	public removeGroupMember(id: any) {
		const method = 'inventory/groups/removemember&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public removeGroup(id: any) {
		const method = 'inventory/groups/removegroup&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public uploadItemGroupFile(formdata: any, eventid: any) {
		const method = 'inventory/groups/upload&id=' + eventid;
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		const p = this.globalSearchService.getSessionItem('package');
		if (p) {
			return '&package=' + p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private handleError < T > (operation = 'operation', result ? : T) {
		return (error: any): Observable < T > => {
			return error;
		};
	}
}
