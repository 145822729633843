import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { EducationService } from '../../services/education.service';
import { InventoryService } from '../../services/inventory.service';
import { Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSortModule, MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, ValidationErrors, FormControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DropzoneComponent, DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-education-events',
	templateUrl: './education-events.component.html',
	styleUrls: ['./education-events.component.scss']
})
export class EducationEventsComponent {
	color = '';
	eventlist: any = [];
	@ViewChild('attendees') attendeeRef: ElementRef;
	@ViewChild('setupModal') setupRef: ElementRef;
	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [5, 10, 20 , 100, 500, 1000];
	pagesizedefault = 20;
	selected_event: any = [];
	loading: any = false;
	eventForm: UntypedFormGroup;
	categoryCtrl:any = new UntypedFormControl('');
	brandCtrl: any = new UntypedFormControl('');
	descCtrl: any = new UntypedFormControl('');
	prodCtrl:any = new UntypedFormControl('');
	files: File[] = [];
	uploadresults: any = [];
	productlist: any = [];
	categories: any = [];
	brands: any = [];
	productlines: any = [];
	eventlocations: any = [];
	eventregions: any = [];
	picklist: any = [];
	//Well this is stupid.
	hours: any = [{disp: '12a', value: '00'},{disp: '1a', value: '01'},{disp: '2a', value: '02'},{disp: '3a', value: '03'},{disp: '4a', value: '04'},{disp: '5a', value: '05'},
				  {disp: '6a', value: '06'},{disp: '7a', value: '07'},{disp: '8a', value: '08'},{disp: '9a', value: '09'},{disp: '10a', value: '10'},{disp: '11a', value: '11'},
				  {disp: '12p', value: '12'},{disp: '1p', value: '13'},{disp: '2p', value: '14'},{disp: '3p', value: '15'},{disp: '4p', value: '16'},{disp: '5p', value: '17'},
				  {disp: '6p', value: '18'},{disp: '7p', value: '19'},{disp: '8p', value: '20'},{disp: '9p', value: '21'},{disp: '10p', value: '22'},{disp: '11p', value: '23'}];
	
	minutes: any =['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30',
					'31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'];
	
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: '300',
		minHeight: '300',
		maxHeight: '300',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
			{ class: 'arial', name: 'Arial' },
			{ class: 'times-new-roman', name: 'Times New Roman' },
			{ class: 'calibri', name: 'Calibri' },
			//{ class: 'comic-sans-ms', name: 'Comic Sans MS' }
		],
		customClasses: [{
			name: 'quote',
			class: 'quote',
		},
		{
			name: 'Title',
			class: 'titleText',
			tag: 'h2',
		},
		],
		sanitize: true,
		toolbarPosition: 'top',
		toolbarHiddenButtons: [
			[],
			['fontSize']
		]
	};

	descriptionHtmlContent: JSON;
	
	constructor(private _changeDetectorRef: ChangeDetectorRef, private fb: UntypedFormBuilder, private inventoryService: InventoryService, private educationService: EducationService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, private location: Location, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
		
		this.educationService.getEvents().subscribe((a) => {
		if(a){
		this.eventlist = a;
		}
		this.setPagination([]);
		this.setPagination(this.eventlist);
		this.eventForm = this.fb.group({
			name: ['', Validators.required],
			code: ['', Validators.required],
			alias: [''],
			web_link:[''],
			date_start: ['',Validators.required],
			date_end: ['',Validators.required],
			end_time_h: ['00'],
			start_time_h: ['00'],
			start_time_m: ['00'],
			end_time_m: ['00'],
			uses_total: ['0',Validators.required],
			uses_customer: ['0',Validators.required],
			discount: [0,Validators.required],
			logged: ['1'],
			region: [''],
			closed: ['0'],
			status: ['1'],
			image: [''],
			locations:[[]],
			artist_name: [''],
			host: [''],
			phone: [''],
			short_description: [''],
			description: [''],
		});
		});
		this.inventoryService.getItemProductlines().subscribe((a) => {
		this.productlines = a;
		});
		this.inventoryService.getItemCategories().subscribe((a) => {
		this.categories = a;
		});
		this.inventoryService.getBrands().subscribe((a) => {
		this.brands = a;
		});
		this.educationService.getLocations().subscribe((a) => {
		this.eventlocations = a;
		});
		this.educationService.getRegions().subscribe((a) => {
		this.eventregions = a;
		});
		
	}
	
	
	setPagination(tableData) {
		this.dataSource = new MatTableDataSource <any> (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();

	}
	viewAttendees(eventid: any){
		const data = {eventid: eventid};
		this.educationService.getAttendees(data).subscribe((a) => {
		this.selected_event = a;
		this.modalService.open(this.attendeeRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
		});
	}
	addEvent(){
	this.productlist = [];
	this.selected_event = false;
	this.eventForm = this.fb.group({
			name: ['', Validators.required],
			code: ['', Validators.required],
			alias: [''],
			web_link:[''],
			date_start: ['',Validators.required],
			date_end: ['',Validators.required],
			uses_total: ['0',Validators.required],
			uses_customer: ['0',Validators.required],
			discount: [0,Validators.required],
			logged: ['1'],
			region: [''],
			closed: ['0'],
			status: ['1'],
			image: [''],
			locations:[[]],
			artist_name: [''],
			host: [''],
			phone: [''],
			short_description: [''],
			description: [''],
		});
		this.modalService.open(this.setupRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
	editEvent(eventid: any){
		const data = {eventid: eventid};
		this.educationService.getEvent(data).subscribe((a) => {
		this.selected_event = a;
		this.eventForm = this.fb.group({
			name: [a.name, Validators.required],
			code: [a.code, Validators.required],
			alias: [a.alias],
			web_link:[a.web_link],
			date_start: [new Date(a.date_start),Validators.required],
			date_end: [new Date(a.date_end),Validators.required],
			end_time_h: [a.end_time_h],
			start_time_h: [a.start_time_h],
			start_time_m: [a.start_time_m],
			end_time_m: [a.end_time_m],
			uses_total: [a.uses_total,Validators.required],
			uses_customer: [a.uses_customer,Validators.required],
			discount: [a.discount,Validators.required],
			logged: [a.logged],
			region: [''],
			closed: [a.closed],
			status: [a.status],
			image: [a.image],
			locations:[a.location.event_locations],
			artist_name: [a.artist_name],
			host: [a.host],
			phone: [a.phone],
			short_description: [a.short_description],
			description: [a.description],
		});
		this.productlist = a.products;

		this.modalService.open(this.setupRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
		});
	}
	saveEvent(){
		
		if(this.eventForm.valid){
		const data = { eventid: this.selected_event.events_id, eventdetails: this.eventForm.value, productlist: this.productlist};
		
		this.educationService.saveEvent(data).subscribe((a) => {
		if(a.success){
		this.eventlist = a.list;
		this.setPagination(this.eventlist);
		this.selected_event = [];
		this.productlist = [];
		this.picklist = [];
		this.modalService.dismissAll();
		} else {
		alert(a.error);
		}
		});
		} else {
		 this.getFormValidationErrors(this.eventForm);
		}
	}
	
	deleteEvent(eventid: any){
		const data = {eventid: eventid};
		this.educationService.deleteEvent(data).subscribe((a) => {
		this.eventlist = a;
		this.setPagination([]);
		this.setPagination(this.eventlist);
		});
	}
	toggleVis(index: any){
		console.log(this.selected_event.history);
		console.log(index);
		this.selected_event.history[index].show = !this.selected_event.history[index].show;
	}
	
	getProducts(){
		const data = {cats: this.categoryCtrl.value, prods: this.prodCtrl.value, brands: this.brandCtrl.value, desc: this.descCtrl.value};
		
		this.educationService.getProducts(data).subscribe((a)=>{
		this.picklist = a;
		});
	}
	
	addProducts(){
		this.picklist.forEach((i)=>{
		const p = {stockid: i.stockid, description: i.description};
		this.productlist.push(p);
		});
		this.picklist = [];
	}
	
	excludeProduct(item: any){
	const index = this.picklist.indexOf(item);
		if (index >= 0) {
			this.picklist.splice(index, 1);
		}
	}
	removeProduct(item: any){
	const index = this.productlist.indexOf(item);
		if (index >= 0) {
			this.productlist.splice(index, 1);
		}
	}
	
	back(): void {
		this.location.back()
	}
	
	onUploadError(event: any) {

	}

	onUploadSuccess(event: any) {

	}
	getFormValidationErrors(form: any) {
		Object.keys(form.controls).forEach((key, index) => {
			const controlErrors: ValidationErrors = form.get(key).errors;

			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					//this.customerForm.controls[index].markAsTouched()
					form.markAllAsTouched();
					//form.markAsUntouched()

					this.globalSearchService.showNotification(key + ' ' + keyError, 'danger', 'bottom', 'right')
				});
			}
		});
	}
	
	onSelect(cat: any, event: any) {
		this.files.push(...event.addedFiles);
		for (let i = 0; i < this.files.length; i++) {
			this.readFile(this.files[i]).then(fileContents => {
				//stuff

				const upload = {
					content: fileContents,
					name: event.addedFiles[0].name,
					mime: event.addedFiles[0].type,
				}

				this.educationService.uploadEduFile(upload, cat).subscribe((results: any) => {
					this.eventForm.get('image').setValue(results.file);
				});

			});
		}
	}

	private async readFile(file: File): Promise < string | ArrayBuffer > {
		return new Promise < string | ArrayBuffer > ((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}

}
