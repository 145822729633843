import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormControl, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { InventoryService } from '../../services/inventory.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PrintService } from '../../services/print.service';


import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { I } from '@angular/cdk/keycodes';
import { ReactiveFormsModule } from '@angular/forms';

import { BommodalComponent } from '../../shared/boms/bommodal/bommodal.component';

import { Chart, registerables } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
;

@Component({
	selector: 'app-inventory-view',
	templateUrl: './inventory-view.component.html',
	styleUrls: ['./inventory-view.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate('150ms', style({ width: '*', height: '*' })),
				animate('150ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate('150ms', style({ width: '0', height: '0' })),
				animate('150ms', style({ opacity: 0 }))
			])
		]),
	],


})
export class InventoryViewComponent implements OnInit {
	@ViewChild('stockAdjust') stockadjRef: ElementRef;
	@ViewChild('productDescription') prodDesc: ElementRef;
	@ViewChild('productBenefits') beneDesc: ElementRef;
	@ViewChild('productFeatures') featDesc: ElementRef;
	@ViewChild('print_history') print_historyRef: ElementRef;

	costingbase = new UntypedFormControl('');
	descControl = new UntypedFormControl('');
	featControl = new UntypedFormControl('');
	beneControl = new UntypedFormControl('');
	overridename = new UntypedFormControl('');
	ingrControl = new UntypedFormControl('');

	hideshowunits = new UntypedFormControl(true);
	hideshowsales = new UntypedFormControl(true);
	hideshowcost = new UntypedFormControl(false);
	consolidated = new UntypedFormControl(true);
	defaultlocation = new UntypedFormControl('');

	filterAttributes = new UntypedFormControl('');
	charttitle = 'Item YoY';
	chart: any = false;
	itemdetails: any = [];
	itemhistory: any = [];
	selectedindex = 0;
	allattributes: any = false;
	filteredattributes: any = false;
	temp: any = false;
	user: any = [];
	binDisplay: any = false;
	hasspecial = false;

	mainImageSrc: string;
	images: any = false;
	editMatrix: any = false;
	descriptionHtmlContent: JSON;
	editing = {
		settings: false,
		extra: false,
		descriptions: false,
		attributes: false,
		images: false,
		prices: false,
		stock: false,
		purchasing: false,
	}

	isEditingAvgCost = false;
	editingAvgCostValue: number;


	addingattribute: any = false;
	color: any = '';
	config: any = [];

	stockid: any = '';
	attributeForm: FormGroup;
	updateingAttributes: any = false;
	activeParent: any = false;
	savekey: any = [];
	openCreditCount: any;
	ref_location: any = false;
	locations: any = [];
	newtitle = '';
	sending: any = false;
	currentyear = '';
	editsallowed: any = {
		discounts: true,
		editar: true,
		editbilling: true,
		editshipping: true,
		editcontact: true,
		editprofile: true,
		editnotes: true,
		takepayment: true,
	}

	selectedimage: string = '';

	constructor(private datePipe: DatePipe, private printService: PrintService, private route: ActivatedRoute, private inventoryService: InventoryService, private location: Location, public router: Router, private modalService: NgbModal, public cdr: ChangeDetectorRef, private fb: FormBuilder, private globalSearchService: GlobalSearchService) {

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.globalSearchService.user.subscribe(u => {
				this.user = u
			})
		});

	}



	ngOnInit(): void {


		this.color = this.globalSearchService.getColor();

		this.globalSearchService.user.subscribe(results => {
			//only run if user is definied
			this.user = results
			if (this.user) {

				if (results.user.defaultlocation.loccode != this.ref_location) {
					this.defaultlocation.setValue(this.user.user.defaultlocation.loccode);
					this.ref_location = false;
				}
			}

			if (!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;
			}

			this.globalSearchService.locations.subscribe(async (results: any) => {
				this.locations = results;

				if (results) {
					const val = [
						this.user.user.defaultlocation.loccode
					];
					this.defaultlocation.setValue(val)
				}
			});
		});

		this.route.params.subscribe(params => {
			const itemnumber = params['id'];
			if (params['id'] != '' && params['id']) {
				this.stockid = params['id'];
				this.selectedindex = 0;
				this.globalSearchService.lastItem.next(this.stockid);
				this.loadItem();
			}
		});


	}

	updateYear(year: string) {

		if (this.currentyear != year) {
			this.currentyear = year;
			return true;
		}

		return false;
	}

	addTitle(text: any) {

		const locations = this.locations.filter((r: any) => {
			return r.loccode == text;
		})

		if (locations) {
			return locations.locationname
		} else {
			return '';
		}

	}

	loadData() {
		const filter = {
			locations: this.defaultlocation.value,
			stockid: this.stockid,
			consolidated: this.consolidated.value
		}

		this.inventoryService.getItemHistoryByLocation(filter).subscribe(results => {
			this.itemhistory = results;
		});
	}

	exportXls() {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(this.print_historyRef.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.itemdetails.item.stockid + 'YY',
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}

	exportPdf() {
		this.sending = true;
		const encoded: string = this.globalSearchService.base64encode(this.print_historyRef.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.itemdetails.item.stockid + 'YY',
			landscape: true,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		});
	}

	getCostTotalYear(year: any) {
		const value = year.cost.reduce(function(accumulator: number, items: any) {
			const addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	getSaleTotalYear(year: any) {
		const value = year.sales.reduce(function(accumulator: number, items: any) {
			const addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	getQtyTotalYear(year: any) {
		const value = year.qty.reduce(function(accumulator: number, items: any) {
			const addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	getGp(price, cost) {
		const priceminuscost = (this.financial(price) - this.financial(cost)) * 100;
		if (this.financial(price) != 0) {
			return (this.financial(priceminuscost / this.financial(price)));
		} else {
			return 0.00;
		}
	}

	//bin roll up function incomplete
	addNegativeBins() {
		const binadd = [];
		const total_negative = 0;
		let total_onhand = 0;
		for (const key in this.itemdetails.bins) {
			for (const vals in this.itemdetails.bins[key]) {

				const thisbinval = this.financial(this.itemdetails.bins[key][vals].quantity);
				total_onhand += thisbinval;
				const bindata = this.itemdetails.bins[key][vals]
				binadd.push(bindata)
				if (thisbinval < 0) {

				}
			}
		}

		const sorted = binadd.sort((n1, n2) => {
			if (n1.quantity > n2.quantity) {
				return -1;
			}

			if (n1.quantity < n2.quantity) {
				return 1;
			}
			return 0;
		});

		this.binDisplay = sorted;
	}


	updateActiveIndex(event: any) {
		this.selectedindex = event;
		switch (this.selectedindex) {
			//force reload item;
			case 0:
				this.loadItem()
				break;
		}
	}

	addAttribute() {
		if (this.attributeForm.valid) {
			this.inventoryService.addAttribute(this.attributeForm.value).subscribe(async (results: any) => {
				if (results.length) {
					this.filteredattributes = results;
				}
				this.attributeFormBuild();
				this.addingattribute = false;
			});
		}
	}

	attributeFormBuild() {
		this.attributeForm = this.fb.group({
			stockid: [this.itemdetails.item.stockid, Validators.required],
			key: ['', Validators.required],
			value: ['', Validators.required]
		});
	}

	toggleAddingAttribute() {
		if (!this.user.user.issalesman) {
			this.addingattribute = (this.addingattribute) ? false : true;
		}
	}

	// test(val: any, key: any){
	// 	return {
	// 		value : val,
	// 		key : key
	// 	}
	// }

	updateAttribute(stockid: string, key: string, value: any, oe: any, savekey: any, type: any) {

		if (key !== savekey) {
			this.globalSearchService.showNotification('Update the fields value before saving', 'danger', 'bottom', 'left');
			return false;
		}

		const update = {
			stockid: stockid,
			key: key,
			value: value,
			oe: oe,
			type: type
		}


		return this.inventoryService.updateAttribute(update).subscribe(async (results: any) => {
			if (results.length) {
				this.filteredattributes = results;
				this.searchAttributes(this.filterAttributes.value);
				this.globalSearchService.showNotification('Updated', 'success', 'bottom', 'left');
			}
			return true;
		});
	}


	loadItem() {
		this.inventoryService.loadItem(this.stockid).subscribe((results: any) => {
			this.itemdetails = results;
			this.selectedimage = results.image;
			this.allattributes = (results.item && results.item.attributes) ? results.item.attributes : [];
			this.filteredattributes = (results.item && results.item.attributes) ? results.item.attributes : [];
			this.itemdetails.bins;
			if(results.item && results.item.actualcost) {
				this.costingbase.setValue(results.item.actualcost);
			} else {
				//this.costingbase.setValue(results.item.actualcost);
			}
			this.descControl.setValue(this.itemdetails.descriptions.description);
			this.featControl.setValue(this.itemdetails.descriptions.features);
			this.beneControl.setValue(this.itemdetails.descriptions.benefits);
			this.ingrControl.setValue(this.itemdetails.descriptions.ingredients);

			this.overridename.setValue(this.itemdetails.descriptions.name);
			this.attributeFormBuild();
			this.addNegativeBins();
			this.router.navigate(['/inventory/view/', this.stockid]);
			this.loadData();
			this.overrideXHR();
		});

	}

	editDescriptionByType(input: String) {
		if (input == 'description') {
			this.descriptionHtmlContent = JSON.parse(JSON.stringify({
				html: '<pre>' + this.itemdetails.descriptions.description + '</pre>',
				type: 'description'
			}));
		}
		if (input == 'features') {
			this.descriptionHtmlContent = JSON.parse(JSON.stringify({
				html: '<pre>' + this.itemdetails.descriptions.features + '</pre>',
				type: 'features'
			}));
		}
		if (input == 'benefits') {
			this.descriptionHtmlContent = JSON.parse(JSON.stringify({
				html: '<pre>' + this.itemdetails.descriptions.benefits + '</pre>',
				type: 'benefits'
			}));
		}
		this.modalService.open(this.prodDesc, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
			const input = JSON.parse(JSON.stringify({ stockid: this.itemdetails.item.stockid, input: this.descriptionHtmlContent['html'] }));
			if (result == 'save description') {
				this.inventoryService.updateItemDescription(input).subscribe(result => {
					this.loadItem();
				});
			}
			if (result == 'save features') {
				this.inventoryService.updateItemFeatures(input).subscribe(result => {
					this.loadItem();
				});
			}
			if (result == 'save benefits') {
				this.inventoryService.updateItemBenefits(input).subscribe(result => {
					this.loadItem();
				});
			}
		}, (reason) => {

		});
	}

	updateForm(control, event){
		control.setValue(event)
	}

	saveDescriptions() {

		const data = {
			stockid: this.itemdetails.item.stockid,
			description: this.descControl.value,
			features: this.featControl.value,
			benefits: this.beneControl.value,
			name: this.overridename.value
		}

		if (this.config.env.package == 'beauty') {
			data['ingredients'] = this.ingrControl.value
		}

		this.inventoryService.updateItemDescription(data).subscribe(result => {
			this.loadItem();
		});
	}

	selectItemLookup(event: any) {
		this.router.navigate(['./orders/entry/' + event.header.debtorno + '/' + event.header.branchcode]);
		//http://localhost:4200/#/orders/entry/107/00
	}

	invoiceOrder(event: any) {
		this.router.navigate(['orders/invoice/' + event + '/dispatch']);
	}

	moveToPo(order: any) {
		this.router.navigate(['/purchasing/' + order.header.supplierno]);
	}

	updateItem(event: any) {

		this.editing = {
			settings: false,
			extra: false,
			descriptions: false,
			attributes: false,
			images: false,
			prices: false,
			stock: false,
			purchasing: false,
		}

		this.loadItem()

	}

	closeAdj() {
		this.modalService.dismissAll();
		this.loadItem()
	}

	searchAttributes(value: string) {
		this.filteredattributes = this.globalSearchService.filterItem(this.allattributes, value, 'label,value,key');
	}

	toggleEditPurchasing() {
		if (!this.user.user.issalesman && this.user.user.viewcost) {
			this.selectedindex = (this.selectedindex != 4) ? 4 : 0;
		}
	}

	toggleEditPrices() {
		if (!this.user.user.issalesman) {
			this.editing.prices = this.editing.prices ? false : true;
		}
	}
	toggleEditExtra() {
		if (!this.user.user.issalesman) {
			this.editing.extra = (this.editing.extra) ? false : true;
		}
	}
	toggleEditImages() {
		if (!this.user.user.issalesman) {
			this.editing.images = (this.editing.images) ? false : true;
		}
	}
	toggleEditSettings() {
		if (!this.user.user.issalesman) {
			this.editing.settings = (this.editing.settings) ? false : true;
		}
	}
	toggleEditStock() {
		if (!this.user.user.issalesman) {
			this.modalService.open(this.stockadjRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {

			});
		}
	}

	setActualCost(newValue: any) {
		if (!this.user.user.issalesman) {
			this.itemdetails.item.actualcost = newValue;
			const data = {
				stockid: this.itemdetails.item.stockid,
				actualcost: this.financial(newValue),
			}
			this.inventoryService.setActualCost(data).subscribe(r => {
				this.loadItem()
			})
		}
	}

	percent(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(4));
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	getSpecial(code: any) {

		let special = [];
		this.hasspecial = false;
		if (this.itemdetails.special) {
			for (const key in this.itemdetails.special.prices) {
				if (key == code) {
					special = this.itemdetails.special.prices[key];
					if (special) {
						this.hasspecial = true;
					}
				}
			}
		}

		return this.financial(special);

	}

	getPlmMarkup(code: any) {
		const markup = 'N/A';
		const operator = '';
		let returnval = '';

		if (this.itemdetails.item.plm) {
			const plm = this.itemdetails.item.plm.filter(r => {
				return r.typeabbrev == code;
			})[0];
			if (plm) {
				switch (plm.operator) {
					case '1':
						returnval = ' +' + this.financial(plm.amount) + '$'
						break;
					case '2':
						returnval = ' *' + this.percent(plm.amount) + '%'
						break;
					default:
						returnval = ' -' + this.financial(plm.amount) + '$'
						break;
				}
			}
		}

		return returnval;
	}

	back(): void {
		this.location.back()
	}

	ngOnChanges(changes: any) {

	}

	openLookup() {
		this.activeParent = this.stockid;
	}

	setData(data: any = []) {

		const dataset = data;


		const _labels = [];
		const _series = [];
		const datapoints = [];

		data.years.forEach((r, index) => {
			const value = {
				value: r.total,
				meta: r.label,
				name: r.label,
				label: r.label,
				data: r.total
			}
			_labels.push(r.label)

			if (this.config.env.package == 'tires') {
				_series.push(r.qty)
			} else {
				_series.push(r.total)
			}

			datapoints.push(value)
		});

		// 		if(this.grouplocations.value) {
		//
		// 		}

		return {
			labels: _labels,
			datasets: [{
				label: this.charttitle,
				data: _series,
				borderWidth: 1,
				fill: false,
				// backgroundColor: [
				//   'rgba(255, 99, 132, 0.2)',
				//   'rgba(255, 159, 64, 0.2)',
				//   'rgba(255, 205, 86, 0.2)',
				//   'rgba(75, 192, 192, 0.2)',
				//   'rgba(54, 162, 235, 0.2)',
				//   'rgba(153, 102, 255, 0.2)',
				//   'rgba(201, 203, 207, 0.2)'
				// ],
			}]
		};
	}

	changeMainImage(image: any) {
		if (this.itemdetails.item) {
			this.itemdetails.item.full_size_image = image.image;
			this.selectedimage = image.file;
		}
	}

	removeMainImage() {

		const data = {
			item: this.itemdetails.item,
			image: (this.selectedimage) ? this.selectedimage : this.itemdetails.item.image
		}

		this.inventoryService.removeMainImage(data).subscribe(result => {
			this.loadItem();
		});
	}

	removeImage(input: any) {
		this.inventoryService.removeImage(input).subscribe(result => {
			this.loadItem();
		});
	}

	getPriceForKey(key: string): number {
		if (this.itemdetails.item.special) {
			if (this.itemdetails.item.special.prices) {
				if (this.itemdetails.item.special.prices.hasOwnProperty(key)) {
					const price = this.itemdetails.item.special.prices[key];

					if (price) {
						return this.financial(price);
					}
				}
			}
		}
		return 0;
	}

	getFirstPriceValue(prices: {
		[key: string]: any
	}): any {
		const keys = Object.keys(prices);
		if (keys.length === 0) {
			return 0;
		}
		const firstKey = keys[0];
		return prices[firstKey];
	}

	formatDate(date: string): string {
		return this.datePipe.transform(date, 'shortDate');
	}

	getFilteredItemStock() {
		// Initialize an array to hold the final filtered items
		const filteredItems = [];

		// Loop through all warehouses to ensure they are included
		this.itemdetails.itemstock.forEach(item => {
			const bins = this.itemdetails.bins[item.loccode];
			let includeItem = false;

			if (bins) {
				// Calculate bin quantities and check conditions
				const binQuantities = bins.map(loc => Number(loc.quantity));
				const hasPositiveAndNegative = binQuantities.some(q => q > 0) && binQuantities.some(q => q < 0);
				let defaultBin = bins.find(bin => bin.defaultbin === '1') || bins.find(bin => Number(bin.quantity) === 0);

				// If conditions are not met, set item quantity to zero and keep only the default or empty bin
				if (!hasPositiveAndNegative) {
					includeItem = true;
				} else {
					// Set item quantity to zero
					item.quantity = "0";
					includeItem = true; // Ensure the item is still included
					// If there's no valid default or empty bin, select the first available bin
					if (!defaultBin) {
						defaultBin = bins[0];
					}
				}

				// Assign the default or empty bin and filter out other bins
				if (defaultBin) {
					item.defaultBin = defaultBin.bin;
					item.quantity = Number(defaultBin.quantity) === 0 ? "0" : item.quantity;
				}

				// Clear the bins array and include only the selected bin in the final item
				this.itemdetails.bins[item.loccode] = [defaultBin];
			} else {
				// Include the item if there are no bins
				includeItem = true;
			}

			// Include the item in the final results if necessary
			if (includeItem) {
				filteredItems.push(item);
			}
		});

		return filteredItems;
	}



	private overrideXHR() {
		const originalXHR = window.XMLHttpRequest;
		const originalOpen = originalXHR.prototype.open;

		originalXHR.prototype.open = function(...args) {
			this.addEventListener('readystatechange', function() {
				if (this.readyState === 4) {
					// No operation, just suppressing the log
				}
			}, false);

			originalOpen.apply(this, args);
		};
	}


	// Method to start editing
	editAvgCost() {
		this.isEditingAvgCost = true;
		this.editingAvgCostValue = this.itemdetails.item.cost;
	}

	// Method to save the edited value
	saveAvgCost() {

		const payload = {
			stockid: this.stockid,
			avgcost: this.editingAvgCostValue
		}
		this.inventoryService.setAvgCost(payload).subscribe(result => {
			this.itemdetails.item.cost = this.editingAvgCostValue;
			this.isEditingAvgCost = false;
			this.loadItem();
		});

	}

	cancelEditAvgCost() {
		this.isEditingAvgCost = false;
	}

}