import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { QRCodeModule } from 'angularx-qrcode';
import { GlAccountsComponent } from './gl-accounts/gl-accounts.component';
import { BankAccountsComponent } from './bank-accounts/bank-accounts.component';
import { CompanySetupComponent } from './company-setup/company-setup.component';
import { CompanyPreferenceComponent } from './company-preference/company-preference.component';
import { SalesPeopleComponent } from './sales-people/sales-people.component';
import { SalesAreasComponent } from './sales-areas/sales-areas.component';
import { CogsAccountsComponent } from './cogs-accounts/cogs-accounts.component';
import { SalesAccountsComponent } from './sales-accounts/sales-accounts.component';
import { ShippingMethodsComponent } from './shipping-methods/shipping-methods.component';
import { CustomerTypesComponent } from './customer-types/customer-types.component';
import { CreditStatusComponent } from './credit-status/credit-status.component';


import { InventoryLocationsComponent } from './inventory-locations/inventory-locations.component';
import { InventoryLocationsCreateComponent } from './inventory-locations/inventory-locations-create/inventory-locations-create.component';
import { InventoryCategoriesComponent } from './inventory-categories/inventory-categories.component';
import { AccountSectionsComponent } from './gl-accounts/account-sections/account-sections.component';
import { AccountGroupsComponent } from './gl-accounts/account-groups/account-groups.component';
import { InventoryUsersComponent } from './inventory-users/inventory-users.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { CdkTreeModule } from '@angular/cdk/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { SystemTypesViewComponent } from './system-types-view/system-types-view.component';
import { TaxGroupsComponent } from './taxes/tax-groups/tax-groups.component';
import { TaxAuthoritiesComponent } from './taxes/tax-authorities/tax-authorities.component';
import { TaxCategoriesComponent } from './taxes/tax-categories/tax-categories.component';
import { TaxesComponent } from './taxes/taxes.component';
import { TaxRatesComponent } from './taxes/tax-rates/tax-rates.component';
import { ApiSettingsComponent } from './api-settings/api-settings.component';

import { InventorySettingsComponent } from './inventory-settings/inventory-settings.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from 'primeng/accordion';
import { MailComponent } from './mail/mail.component';
import { ProdlineMarkupsComponent } from './prodline-markups/prodline-markups.component';
import { ReportBuilderComponent } from './report-builder/report-builder.component';

import { SharedModule } from '../shared/shared.module';
import { ProductlineMergeComponent } from './productline-merge/productline-merge.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { StoreMaintenanceComponent } from './store-maintenance/store-maintenance.component';
import { GlClassesComponent } from './gl-classes/gl-classes.component';
import { CustomerGroupsComponent } from './customer-groups/customer-groups.component';
import { CouponSetupComponent } from './coupon-setup/coupon-setup.component';
import { ProductGroupsComponent } from './product-groups/product-groups.component';
import { MoveTransactionComponent } from './move-transaction/move-transaction.component';
import { MergeCustomersComponent } from './merge-customers/merge-customers.component';
import { StorefrontSettingsComponent } from './storefront-settings/storefront-settings.component';
import { CategoriesComponent } from './storefront-settings/categories/categories.component';
import { NestableModule } from 'ngx-nestable';
import { BannersComponent } from './storefront-settings/banners/banners.component';
import { SaleBuilderComponent } from './sale-builder/sale-builder.component';
import { CustomerApprovalComponent } from './storefront-settings/customer-approval/customer-approval.component';
import { InformationComponent } from './storefront-settings/information/information.component';
import { PaymentTypesComponent } from './payment-types/payment-types.component';
import { QrCodesComponent } from './storefront-settings/qr-codes/qr-codes.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
	declarations: [
		GlAccountsComponent,
		BankAccountsComponent,
		CompanySetupComponent,
		CompanyPreferenceComponent,
		SalesPeopleComponent,
		SalesAreasComponent,
		CogsAccountsComponent,
		SalesAccountsComponent,
		ShippingMethodsComponent,
		CustomerTypesComponent,
		CreditStatusComponent,
		InventoryLocationsComponent,
		InventoryLocationsCreateComponent,
		AccountSectionsComponent,
		AccountGroupsComponent,
		InventoryCategoriesComponent,
		InventoryUsersComponent,
		SystemTypesViewComponent,
		TaxGroupsComponent,
		TaxAuthoritiesComponent,
		TaxCategoriesComponent,
		TaxesComponent,
		TaxRatesComponent,
		ApiSettingsComponent,
		InventorySettingsComponent,
		MailComponent,
		ProdlineMarkupsComponent,
		ReportBuilderComponent,
		ProductlineMergeComponent,
		StoreMaintenanceComponent,
		GlClassesComponent,
		CustomerGroupsComponent,
		CouponSetupComponent,
		ProductGroupsComponent,
		MoveTransactionComponent,
		MergeCustomersComponent,
		StorefrontSettingsComponent,
		CategoriesComponent,
		BannersComponent,
		SaleBuilderComponent,
		CustomerApprovalComponent,
		InformationComponent,
		PaymentTypesComponent,
  QrCodesComponent,
	],
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		NgxDropzoneModule,
		FormsModule,
		MatToolbarModule,
		MatProgressBarModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatInputModule,
		MatTableModule,
		MatCheckboxModule,
		MatSortModule,
		MatPaginatorModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatExpansionModule,
		MatSelectModule,
		MatMenuModule,
		MatIconModule,
		MatSlideToggleModule,
		MatStepperModule,
		MatListModule,
		MatTabsModule,
		NgSelectModule,
		SharedModule,
		CdkTreeModule,
		DragDropModule,
		AngularEditorModule,
		NestableModule,
		AccordionModule,
		QRCodeModule,
		CKEditorModule
	]
})
export class SystemModule { }
